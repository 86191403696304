<template>
  <div>
    <div class="wrapper position-relative mb-4">
      <img
        v-if="image"
        :src="image"
        alt="image"
        class="d-none d-lg-block placeholder-img m-auto position-absolute w-25"
      >
      <div class="placeholder timeline" :style="{ backgroundImage: `url(${images[0]})` }" />
    </div>

    <div class="wrapper position-relative mb-4">
      <div class="placeholder breakdown" :style="{ backgroundImage: `url(${images[1]})` }" />
    </div>

    <div class="wrapper position-relative mb-4">
      <div class="placeholder table" :style="{ backgroundImage: `url(${images[2]})` }" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyPlaceholder',

  props: {
    image: {
      type: String,
      default: ''
    },
    images: {
      type: Array,
      default: () => {
        return [
          require('@/assets/img/placeholders/timeline-graph.svg'),
          require('@/assets/img/placeholders/graph-dummy.svg'),
          require('@/assets/img/placeholders/table-graph.svg')
        ]
      }
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  border: 2px solid #ececef;
  border-radius: 8px;
  padding: 10px 20px 0;
}

.placeholder {
  width: 100%;
  height: auto;
  min-height: 320px;
  background-repeat: no-repeat;

  @include media-breakpoint-up($lg) {
    background-size: 100%;
  }
}

.placeholder-img {
  left: 0;
  right: 0;
  top: 20px;
}
</style>
