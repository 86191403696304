<template>
  <chart-component>
    <!-- Title -->
    <template #title>
      {{ $t('charts.distributionOfOtherOverheads') }}
    </template>
    <!-- /Title -->
    <!-- Subtitle -->
    <template #subtitle>
      {{ $t('pages.businessGuide.monthlyAverage') }}
    </template>
    <!-- /Subtitle -->
    <!-- Chart -->
    <template #chart>
      <distribution-of-other-overheads-chart
        hide-title
        format-labels
        :chart-animations="chartAnimations"
      />
    </template>
    <!-- /Chart -->
    <!-- Right side -->
    <template #right-side>
      <chart-component-right-side>
        <total-amount
          class="mb-3"
          :amount="totalAmount"
          :text="$t('totalMonthlyAverage')"
        />
        <div>
          <p class="m-0 text-small mb-4">
            {{ $t('topThreeAverage') }}
          </p>
          <template v-for="(otherOverhead, index) in topOtherOverheads">
            <year-info
              :key="index"
              class="mb-3"
              :avatar-text="index + 1"
              :text="otherOverhead.name"
              circle
              :money="otherOverhead.average"
            />
          </template>
        </div>
      </chart-component-right-side>
    </template>
    <!-- /Right side -->
  </chart-component>
</template>

<script>
import ChartComponent from '@/views/Home/StoryMode/FinancialPlan/Components/ChartComponent'
import ChartComponentRightSide from '@/views/Home/StoryMode/FinancialPlan/Components/ChartComponentRightSide'
import DistributionOfOtherOverheadsChart from '@/views/Home/StoryMode/Forecast/OtherOverheads/Charts/DistributionOfOtherOverheadsChart'
import TotalAmount from '@/views/Home/StoryMode/FinancialPlan/Components/TotalAmount'
import YearInfo from '@/views/Home/StoryMode/FinancialPlan/Components/YearInfo'

export default {
  name: 'DistributionOfOtherOverheads',

  components: {
    ChartComponentRightSide,
    ChartComponent,
    DistributionOfOtherOverheadsChart,
    TotalAmount,
    YearInfo
  },

  props: {
    chartAnimations: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    totalAmount () {
      let result = 0

      if (this.$store.state.idea.storyMode.forecast.otherOverheads.breakdown.totalOverhead) {
        result = this.$store.state.idea.storyMode.forecast.otherOverheads.breakdown.totalOverhead
      }

      return result
    },

    topOtherOverheads () {
      let result = []

      if (this.$store.state.idea.storyMode.forecast.otherOverheads.breakdown.sorted) {
        result = this.$store.state.idea.storyMode.forecast.otherOverheads.breakdown.sorted
      }

      return result
    }
  }
}
</script>
