<template>
  <chart-component>
    <!-- Title -->
    <template #title>
      {{ $t('charts.otherOverheadsOverTime') }}
    </template>
    <!-- /Title -->
    <!-- Timeline Period Switcher -->
    <template #switcher>
      <timeline-period-switcher
        class="switcher"
        @change="switchChartPeriod($event)"
      />
    </template>
    <!-- /Timeline Period Switcher -->
    <!-- Chart -->
    <template #chart>
      <other-overheads-over-time-chart
        :period="period"
        hide-title
        :chart-animations="chartAnimations"
        :shared-idea="sharedIdea"
      />
    </template>
    <!-- /Chart -->
    <!-- Right side -->
    <template #right-side>
      <chart-component-right-side>
        <total-amount
          class="mb-3"
          :amount="totalAmount"
          :text="$t('charts.totalCost')"
        />
        <div>
          <template v-for="(year, index) in byYearAmount">
            <year-info
              :key="index"
              class="mb-3"
              :avatar-text="`Y${index + 1}`"
              :money="year"
              :text="getTotalExpensesEndOfYearDateString(index)"
            />
          </template>
        </div>
      </chart-component-right-side>
    </template>
    <!-- /Right side -->
  </chart-component>
</template>

<script>
import ChartComponent from '@/views/Home/StoryMode/FinancialPlan/Components/ChartComponent'
import ChartComponentRightSide from '@/views/Home/StoryMode/FinancialPlan/Components/ChartComponentRightSide'
import MixinCalculations from '@/mixins/calculations'
import OtherOverheadsOverTimeChart from '@/views/Home/StoryMode/Forecast/OtherOverheads/Charts/OtherOverheadsOverTimeChart'
import TimelinePeriodSwitcher from '@/components/Charts/Timeline/TimelinePeriodSwitcher'
import TotalAmount from '@/views/Home/StoryMode/FinancialPlan/Components/TotalAmount'
import YearInfo from '@/views/Home/StoryMode/FinancialPlan/Components/YearInfo'

export default {
  name: 'OtherOverheadsOverTime',

  components: {
    ChartComponentRightSide,
    OtherOverheadsOverTimeChart,
    ChartComponent,
    TimelinePeriodSwitcher,
    TotalAmount,
    YearInfo
  },

  mixins: [MixinCalculations],

  props: {
    chartAnimations: {
      type: Boolean,
      default: false
    },
    sharedIdea: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      period: 'monthly',
      dateStringPeriodEndMonth: this.getDateStringPeriodEndMonth(),
      dateStringPlanningStartYear: this.getDateStringPlanningStartYear()
    }
  },

  computed: {
    totalAmount () {
      return this.$store.state.idea.storyMode.forecast.otherOverheads.timeline.total
    },

    byYearAmount () {
      return this.$store.state.idea.storyMode.forecast.otherOverheads.timeline.byYear
    }
  },

  methods: {
    switchChartPeriod (period) {
      this.$set(this, 'period', period)
    },

    getTotalExpensesEndOfYearDateString (year) {
      return `${this.$t('charts.endOf')} ${this.dateStringPeriodEndMonth} ${this.$moment(this.dateStringPlanningStartYear, 'YYYY').add(year + 1, 'Y').format('[\']YY')}`
    }
  }
}
</script>
