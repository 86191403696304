import EventBus from '@/event-bus'

export default {
  mounted () {
    EventBus.$on('open-ai-buddy', () => {
      this.aiBuddyDrawerVisible = true
    })
  },

  data () {
    return {
      aiBuddyDrawerVisible: false
    }
  },

  beforeDestroy () {
    EventBus.$off('open-ai-buddy')
  }
}
