<template>
  <div>
    <loader v-if="loading" />
    <template v-else>
      <div class="d-flex justify-content-end mb-4 margin-guide-not-visible">
        <erase-icon-button
          v-if="removeButtonVisible"
          class="mr-2"
          @click="onDelete"
        />
      </div>

      <template v-if="!isOtherOverheadEmpty">
        <ib-card
          :animation="false"
          class="mb-4 pt-4 pb-1"
        >
          <distribution-of-other-overheads />

        </ib-card>
        <ib-card
          :animation="false"
          class="mb-4 pt-4 pb-1"
        >
          <other-overheads-over-time />

        </ib-card>
        <ib-card
          :animation="false"
          class="pt-4 pb-2"
        >
          <other-overheads-table />
        </ib-card>
      </template>

      <empty-placeholder v-else />
    </template>

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="otherOverheadsExamples"
      :visible="guideVisible"
      @close="guideVisible = false"
    />
    <!-- /Info Guide Drawer -->

    <!-- Ai Buddy Drawer -->
    <ai-buddy-drawer
      :visible="aiBuddyDrawerVisible"
      type="other-overheads"
      @close="aiBuddyDrawerVisible = false"
    />
    <!-- /Ai Buddy Drawer -->

    <!-- Dialog Add Other Overhead -->
    <dialog-add-other-overhead
      v-if="canEdit"
      :dialog-add-other-overhead-visible="dialogAddNewVisible"
      :options="addOtherOverheadOptions"
      @close-dialog-add-other-overhead="dialogAddNewVisible = false"
      @close-dialog-on-button="dialogAddNewVisible = false"
      @on-update-other-overheads-view="onUpdateAddNewDialog()"
    />
    <!-- /Dialog Add Other Overhead -->
  </div>
</template>

<script>
import AiBuddyDrawer from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyDrawer.vue'
import DialogAddOtherOverhead from '@/views/Home/StoryMode/Forecast/OtherOverheads/Dialogs/DialogAddOtherOverhead'
import DistributionOfOtherOverheads from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/OtherOverheads/DistributionOfOtherOverheads'
import EmptyPlaceholder from '@/views/Home/StoryMode/FinancialPlan/Components/EmptyPlaceholder'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import EventBus from '@/event-bus'
import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'
import Loader from '@/components/Loader'
import MixinAiBuddyDrawer from '@/mixins/aiBuddyDrawer'
import MixinApiCalculations from '@/mixins/apiCalculations'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import OtherOverheadsOverTime from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/OtherOverheads/OtherOverheadsOverTime'
import OtherOverheadsTable from '@/views/Home/StoryMode/Forecast/OtherOverheads/Tables/OtherOverheadTable'
import addOtherOverheadOptions from '@/views/Home/StoryMode/Forecast/OtherOverheads/Dialogs/addOtherOverheadOptions'
import { mapState } from 'vuex'

export default {
  name: 'ExpensesOtherOverheads',

  components: {
    AiBuddyDrawer,
    InfoGuideDrawer,
    DialogAddOtherOverhead,
    DistributionOfOtherOverheads,
    EmptyPlaceholder,
    EraseIconButton,
    Loader,
    OtherOverheadsOverTime,
    OtherOverheadsTable
  },

  mixins: [MixinApiCalculations, MixinGuide, MixinIdeaRoles, MixinDeleteIdeaExamples, MixinAiBuddyDrawer],

  data () {
    return {
      loading: false,
      dialogAddNewVisible: false,
      guideVisible: false,
      addOtherOverheadOptions
    }
  },

  computed: {
    ...mapState(['idea']),

    isOtherOverheadEmpty () {
      return this.idea.storyMode.forecast.otherOverheads.otherOverheads.length === 0
    },

    removeButtonVisible () {
      return this.canEdit && this.otherOverheadHasItemsFromTemplate
    }
  },

  mounted () {
    this.openGuide('otherOverheadsFinancialPlanGuide')
    EventBus.$on('add-edit', () => {
      this.onShowAddNewDialog()
    })

    EventBus.$on('open-info', () => {
      this.guideVisible = true
    })

    EventBus.$on('remove-examples', () => {
      this.deleteExamples()
    })
  },

  beforeDestroy () {
    EventBus.$off('add-edit')
    EventBus.$off('open-info')
    EventBus.$off('remove-examples')
  },

  methods: {
    onShowAddNewDialog () {
      this.getOtherOverheads()
      this.dialogAddNewVisible = true
    },

    onUpdateAddNewDialog () {
      this.loading = true
      Promise.all([
        this.getOtherOverheads(),
        this.getTotalCosts(),
        this.getProfitAndCashFlow()
      ])
        .then(() => {
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },

    deleteExamples () {
      this.deleteExamplesMixin('other-overheads')
        .then(_ => { this.onUpdateAddNewDialog() })
    }
  }
}
</script>
